<template>
  <v-app>
    <component :user="user" :is="layout">
      <router-view :user="user" :layout.sync="layout" />
    </component>
  </v-app>
</template>

<script>
// import * as fb from '@/firebase'
import 'animate.css'

import '@/app.css'

export default {
  name: 'App',

  components: {},

  data: () => ({
    layout: `div`,
    user: {},
    authUser: {},
    isAdmin: false,
  }),
  // watch: {
  //   authUser: {
  //     immediate: true,
  //     handler() {
  //       if (this.user) {
  //         this.user = {
  //           ...this.authUser,
  //           isAdmin: this.isAdmin,
  //           id: this.authUser.id,
  //         }
  //         console.log(this.user)
  //       }
  //     },
  //   },
  // },
  // async created() {
  //   if (fb.auth.currentUser) {
  //     this.$bind('authUser', fb.usersCollection.doc(fb.auth.currentUser.uid))

  //     fb.auth.currentUser.getIdTokenResult().then((claims) => {
  //       let userClaims = claims.claims
  //       this.isAdmin = userClaims.admin
  //       console.log(userClaims)
  //     })
  //   }
  // },
}
</script>
