import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import VueMq from 'vue-mq'
import VueKinesis from 'vue-kinesis'

Vue.config.productionTip = false
Vue.use(VueKinesis)

Vue.use(VueMq, {
  breakpoints: {
    mobile: 450,
    tablet: 1181,
    laptop: 1250,
    desktop: Infinity,
  },
})

new Vue({
  router,
  vuetify,
  render: (h) => h(App),
}).$mount('#app')
