import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    options: {
      customProperties: true,
    },
    light: true,
    themes: {
      light: {
        primary: '#000000',
        secondary: '#E3C7AB',
        tertiary: '#5E442A',
        background: '#faf8f2',
        darkgrey: '#242424',
      },
    },
  },
})
